var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "pb-0",
      staticStyle: {
        "background-image":
          "linear-gradient(141deg, #9fb8ad 0%, #17a7c1 90%, #17a7c1 90%)"
      },
      attrs: { id: "testimonials" }
    },
    [
      _c("p", { staticClass: "text-center caption white--text ma-0" }, [
        _vm._v("TESTIMONIALS")
      ]),
      _c("section-header", {
        staticClass: "white--text",
        staticStyle: { "margin-bottom": "0px !important" },
        attrs: { "sub-header": "Endorsed by leading academics" }
      }),
      _c(
        "div",
        {
          staticClass: "d-flex justify-center",
          staticStyle: { height: "100%" }
        },
        [
          _c(
            "v-carousel",
            {
              staticStyle: { "max-width": "1100px" },
              attrs: {
                "hide-delimiters": "",
                "next-icon": "mdi-arrow-right-thick",
                "prev-icon": "mdi-arrow-left-thick",
                "hide-delimiter-background": "",
                height: "auto"
              }
            },
            _vm._l(_vm.quotes, function(quote) {
              return _c("v-carousel-item", { key: quote.author }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center justify-center px-10" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-justify px-5",
                        staticStyle: { "max-width": "800px" }
                      },
                      [
                        _c(
                          "blockquote",
                          { staticClass: "blockquote white--text pa-0" },
                          [
                            _c("v-icon", [_vm._v("mdi-format-quote-open")]),
                            _vm._v(" " + _vm._s(quote.quote) + " "),
                            _c("v-icon", [_vm._v("mdi-format-quote-close")])
                          ],
                          1
                        ),
                        _c("div", { staticClass: "text-center pt-5" }, [
                          _c(
                            "p",
                            {
                              staticClass:
                                "white--text font-weight-bold title mt-5",
                              attrs: {
                                "mt-10": _vm.$vuetify.breakpoint.mdAndUp
                              }
                            },
                            [_vm._v(_vm._s(quote.author))]
                          ),
                          _c(
                            "p",
                            {
                              staticClass:
                                "white--text overline text--uppercase"
                            },
                            [_vm._v(_vm._s(quote.affiliation))]
                          )
                        ])
                      ]
                    )
                  ]
                )
              ])
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }