<template>
    <section id="testimonials" class="pb-0" style="background-image: linear-gradient(141deg, #9fb8ad 0%, #17a7c1 90%, #17a7c1 90%);">
        <p class="text-center caption white--text ma-0">TESTIMONIALS</p>
        <section-header sub-header="Endorsed by leading academics" class="white--text" style="margin-bottom:0px !important;" />
        <div style="height:100%" class="d-flex justify-center">
            <v-carousel
                hide-delimiters
                next-icon="mdi-arrow-right-thick"
                prev-icon="mdi-arrow-left-thick"
                hide-delimiter-background
                style="max-width: 1100px;"
                height="auto"
            >
                <v-carousel-item v-for="quote in quotes" :key="quote.author">
                    <div class="d-flex align-center justify-center px-10">
                        <div class="text-justify px-5" style="max-width: 800px">
                            <blockquote class="blockquote white--text pa-0">
                                <v-icon>mdi-format-quote-open</v-icon>
                                {{ quote.quote }}
                                <v-icon>mdi-format-quote-close</v-icon>
                            </blockquote>
                            <div class="text-center pt-5">
                                <p :mt-10="$vuetify.breakpoint.mdAndUp" class="white--text font-weight-bold title mt-5">{{ quote.author }}</p>
                                <p class="white--text overline text--uppercase">{{ quote.affiliation }}</p>
                            </div>
                        </div>
                    </div>
                </v-carousel-item>
            </v-carousel>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            quotes: [
                {
                    author: 'Ines Chaieb',
                    affiliation: 'ASSOCIATE PROFESSOR OF FINANCE AT THE UNIVERSITY OF GENEVA',
                    quote:
                        "My data needs to conduct research are quite demanding. Alphacruncher changed my whole perspective on data management, storage, and sharing. I was able to boost research efficiency, teach more efficiently, and make learning an enjoyable activity. I was impressed by the skills, flexibility, and professionalism of Alphacruncher's team. I strongly recommend Alphacruncher's products and experts' advice for achieving growth in academia, disseminating productive research culture, and promote efficient education."
                },
                {
                    author: 'Tamara Nefedova',
                    affiliation: 'Assistant Professor of Finance at Université Paris-Dauphine',
                    quote:
                        'Alphacruncher is a team of professional and inventive data scientists who actually understand the needs faced by researchers nowadays. I am thankful to the team for their tremendous help on a couple of my data-intensive projects that worked out successfully. I look forward to our future collaboration!'
                },
                {
                    author: 'Costanza Naguib',
                    affiliation: 'Assistant Professor of Econometrics at the University of Bern',
                    quote:
                        'In doing my research, I often work with computationally-expensive tasks. Thanks to Alphacruncher, I have been able to reduce my computational times and increase my research productivity.'
                },
                {
                    author: 'Oliver Scaillet',
                    affiliation: 'Professor of Finance, University of Geneva and SFI senior chair',
                    quote: 'Fast running of your programs and data access from home or everywhere in the world, what else?'
                },
                {
                    author: 'Andras Fulop',
                    affiliation: 'Professor of Finance, ESSEC Business School',
                    quote:
                        'I am teaching computationally intensive courses in finance. For several years I have been looking for a solution that is both easy to use and can accommodate modern computational frameworks. I have tried Nuvolos this year, and it was a great experience. The service is easy to use and provides an integrated platform for data and computing. Special thanks to the Nuvolos team for their reactivity in solving any emerging questions.'
                },
                {
                    author: 'Gabor Bekes',
                    affiliation: 'Assistant Professor, Central European University',
                    quote:
                        'I have been teaching Data Analysis, a course based on my forthcoming textbook at Cambridge University Press for many years for Economics and Business Analytics students. Our students have a diverse background in coding, and I have been looking for a solution to simplify seminars in R and enhance the student experience. Nuvolos offered a simple unified platform for all students, avoiding problems using different versions of R or individual packages as well as differences in computer OS, folder structures, etc. Assignment submission and grading were made easier, too, as reproducibility was guaranteed. Amazing and fast technical support added a safety net in technically challenging situations.'
                },
                {
                    author: 'Jeremy Heng',
                    affiliation: 'Assistant Professor, ESSEC Business School',
                    quote:
                        'I have taught two courses on Nuvolos using the RStudio application that it supports. Students found the interface friendly and simple to navigate. From a pedagogical perspective, I particularly liked having identical virtual environments in class and the ease of sharing files and datasets with students. Overall my experience was very smooth: I only had one or two issues, which were quickly resolved by their support team. As a research resource, Nuvolos aims to offer simplified access to version control and compute clusters. I particularly like their simplification of the latter. Instead of having to write job submission shell scripts and submitting them via secure shell (which is a pain), one can just submit jobs to the Nuvolos cluster from RStudio. What is even nicer is that the results of each job are then copied from the cluster to the directory of your local file automatically, sparing one the pain of having to do file transfers using secure copy. I think the bottom line here is that a user will never need to write commands on a terminal to run a job and receive the results - life is simply too short for this!'
                }
            ]
        }
    }
}
</script>
